import { useState, useContext, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SessionContext } from "../../shared/context/session-provider";
import { GlobalContext } from "../../shared/context/global-provider";
import { ShareContext } from "../../shared/context/share-state";
import { useMetadata } from "../../shared/context/meta-provider";
import AssetList from '../asset-list';
import Localization from '../../shared/localization';
import ViewAssets from '../../shared/components/view-asset';
import UsersService from '../../../services/api/usersService';
import AssetService from '../../../services/api/assetsService';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import GenericModal from '../../shared/components/generic-modal';
import ViewAssetsHooks from '../../shared/hooks/view-assets-hooks';
import SearchHooks from '../../shared/hooks/search-hooks';
import SearchIcon from '../../icons/search';
import SolutionManifest from "../../../solution-manifest";
import CustomIcons from "../../shared/components/custom-icons";
import DOMPurify from "dompurify";
import path from "../../shared/routePaths";
import NotificationBanner from "../../shared/components/notification-banner";
import MobileModal from "../../shared/components/mobile-modal";
import FilterCenterFocusIcon from "../../icons/filtercenterfocus";
import CloseIcon from "../../icons/close";
import ImageSearchUpload from "../image-search-upload";
import { useDropzone } from "react-dropzone";
import { UploadContext } from "../../shared/context/upload-provider";
import { IsTablet } from "../../shared/utils";
import NotificationService from "../../../services/api/notificationService";

const ExploreView = (props) => {
    const [data, setData] = useState(null);
    const [owners, setOwners] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalShow, SetModalShow] = useState(false);
    const [modalImageLoadingShow, SetModalImageLoadingShow] = useState(false);
    const [modalType, SetModalType] = useState("");
    const [deleteFilesData, setDeleteFilesData] = useState({});
    const [inputFocus, SetInputFocus] = useState(false);
    const imageSearchBox = useRef();

    const handleInputFocus = () => {
        SetInputFocus(true);
        setIsImageSearchModalOpen(false);
        setIsImageSearchHeaderModalOpen(false);
    };

    const handleInputBlur = () => {
        SetInputFocus(false);
        setIsImageSearchModalOpen(false);
        setIsImageSearchHeaderModalOpen(false);
    };

    const { inputValue,
        updateSearchValue,
        setInputValue,
        setSelectedOption,
        setSearchKeyword,
        setShowSearch,
        setSearchSubmitted } = SearchHooks();

    const {
        progress,
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        nextUUID,
        prevUUID,
        viewAssetsShow,
        viewAssetBtnDisable,
    } = ViewAssetsHooks(props);

    const { tenantUuid,
        productUrl,
        subscriptionPlan,
        isCustomerAdmin,
        userUuid } = useContext(SessionContext);

    // Share Context
    const { updateMenuTabKey,
        mobileNavigationMenu,
        isMobileModalOpen,
        handleMobileModalOpen,
        handleMobileModalClose,
        isTablet,
        setIsImageSearch,
        isImageSearchModalOpen,
        setIsImageSearchModalOpen,
        setIsImageSearchHeaderModalOpen,
        setImgSrc,
     } = useContext(ShareContext);

    const { uploadImageFileData, cancelUploadProcess  } = useContext(UploadContext);
    const { updateToastMessage } = useContext(GlobalContext);

    const [notificationBannerShow, setNotificationBannerShow] = useState(false);
    const [notificationBannerContent, setNotificationBannerContent] = useState("");
    const [notificationBannerVariant, setNotificationBannerVariant] = useState("");
    const pathPrefix = '/';

    const searchInputRef = useRef();
    const imageinputRef = useRef();

    // Refresh MetaData
    const { refreshMetadata } = useMetadata();

    //----- Function -----//
    const options = [
        { value: '', label: 'All Assets', iconUrl: './images/icons/all_assets.svg' },
        { value: 'image', label: 'Images', iconUrl: './images/icons/images.svg' },
        { value: 'video', label: 'Videos', iconUrl: './images/icons/videos.svg' },
        { value: 'document', label: 'Documents', iconUrl: './images/icons/documents.svg' },
        { value: 'audio', label: 'Audio', iconUrl: './images/icons/audio.svg' },
    ];

    const onDrop = async (files) => {
        if(IsTablet) {
            SetModalImageLoadingShow(true);
        }
        const messageInfo = await uploadImageFileData(files);

        if (!messageInfo) {
            setShowSearch(true);
            setSearchSubmitted(true);
            setIsImageSearch(true);
            updateMenuTabKey(path.SEARCH);
        } else {
            updateToastMessage(`${messageInfo}`,"error");
        }
        imageinputRef.current.value = null;
    };

    const { getInputProps } = useDropzone({ onDrop });

    const updateNotificationBanner = (notificationBannerShow, notificationBannerContent, notificationBannerVariant) => {
        setNotificationBannerShow(notificationBannerShow);
        setNotificationBannerContent(notificationBannerContent);
        setNotificationBannerVariant(notificationBannerVariant);
    };

    const [selectedItem, setSelectedItem] = useState(options[0].label);

    const dropdownItems = options.map((option) => (
        <Dropdown.Item key={option.value} eventKey={option.label} active={selectedItem === option.label}>
            <span>{option.label}</span>
        </Dropdown.Item>
    ));

    const mobileFileTypeList = options.map((option) => (
        <li key={option.value} onClick={() => { handleSelect(option.label); handleMobileModalClose(); }} className={selectedItem === option.label ? 'active' : ''}>
            <div>
                <img src={option.iconUrl} alt={option.label} />
            </div>
            <h3>{option.label}</h3>
        </li>
    ));

    const handleSelect = (eventKey) => {
        setSelectedItem(eventKey);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleInput = (event) => {
        setInputValue(event.target.value);
    };

    const handleClick = () => {
        const selectedOptionValue = options.find(option => option.label === document.getElementById('dropdown-options').innerText).value;
        const selectedKeywordsValue = searchInputRef.current.value;

        if(selectedKeywordsValue && selectedKeywordsValue.length > 2)
        {
            setSelectedOption(selectedOptionValue);
            setSearchKeyword(selectedKeywordsValue);
            setShowSearch(true);
            setSearchSubmitted(true);
            setIsImageSearch(false);
            updateMenuTabKey(path.SEARCH);
        }
    };

    const handleClearClick = () => {
        setInputValue('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    };

    const handleImageSearchClick = () => {
        setImgSrc("");
        setIsImageSearchModalOpen(true);
        setIsImageSearchHeaderModalOpen(false);
    }

    // Get Owner APIs
    const getOwner = async () => {
        const usersPromise = await UsersService.getUsers();
        setOwners(usersPromise);
    }

    // Update Asset Data State
    const updateAssetData = (assetData) => {
        setData(assetData);
    }

    const handleModalDelete = async () => {
        const bodyAsset = {
            "uuid": tenantUuid,
            "useruuid": userUuid,
            "assetuuids": [deleteFilesData.uuid]
        }

        await SetModalShow(false);
        await AssetService.purgeRecord(bodyAsset, tenantUuid).then(() => {
            const filterData = data.filter(data => data.uuid !== deleteFilesData.uuid);
            setData(filterData);
            // Set Toast Message
            updateToastMessage(`${deleteFilesData.name} deleted`);
            // Refresh Meta Data
            refreshMetadata();
        })
          .catch((e) =>
            console.error(`fail to remove media ${deleteFilesData.uuid}`));
    }

    // Handler Modal Close
    const handleModalClose = () => SetModalShow(false);

    // Handler Image Loading Modal Close
    const handleImageLoadingModalClose = () => {
        SetModalImageLoadingShow(false);
    }

    // Remove file Modal Trigger
    const handleRemoveFileModal = (fileName, fileUUID) => {
            const newDeleteFilesData = {
                name: fileName.substring(fileName.lastIndexOf("/") + 1),
                uuid: fileUUID
            };

            setDeleteFilesData(newDeleteFilesData);
            SetModalType('delete');
            SetModalShow(true);
    }

    useEffect(() => {
        const fetchFreeTrial = async () => {
            if (subscriptionPlan !== SolutionManifest.SubscriptionPlan.FreeTrialId) {
                return;
            }

            const freeTrial = await UsersService.freeTrial(tenantUuid);

            if (freeTrial.code === 200) {
                const trial_days_remaining = freeTrial.response.trial_days_remaining;
                const trial_extension = freeTrial.response.trial_extension;
                const shouldShowError = trial_days_remaining <= 3;

                if (shouldShowError) {
                    //check if cookie exists
                    const value = `; ${document.cookie}`;
                    const parts = value.split(`; trial_ending=`);
                    let cookieExist;
                    if (parts.length === 2) {
                        cookieExist = parts.pop().split(";").shift();
                    }
                    if (!cookieExist) {
                        const response = await NotificationService.createNotification(tenantUuid, userUuid, "trial ending")
                        if (response.statusCode === 200) {
                            //creates a cookie that expires in 3 days to prevent multiple notifications
                            const date = new Date();
                            date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));
                            document.cookie = `trial_ending=${true}; expires=${date.toUTCString()}; path=/`;
                        }
                    }
                }

                const generateNotificationContent = (type, linkText, linkUrl, linkTarget) => {
                    const trial_time_remaining = freeTrial.response.trial_time_remaining;
                    const errorVariant = shouldShowError ? <CustomIcons variant="error" color="#D80029" /> : null;

                    return (<>
                        <div>
                            {errorVariant}
                            <span className="desktop-notification-content-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                                Localization.Plan.FreeTrial.NotificationBanner[type].replace(
                                    /\${trial_days_remaining}/g,
                                    trial_days_remaining === 0 ? `<strong>${trial_time_remaining}</strong>` : trial_days_remaining === 1 ? `<strong>${trial_days_remaining} day</strong>` :`<strong>${trial_days_remaining} days</strong>`
                                )
                            )}}></span>
                            <span className="mobile-notification-content-text">
                                Free trial ends in <strong>{trial_days_remaining === 0 ? `${trial_time_remaining}` : trial_days_remaining === 1 ? `${trial_days_remaining} day` :`${trial_days_remaining} days`}</strong>
                            </span>
                            {linkText && <a href={linkUrl} target={linkTarget} className="btn btn-primary btn-sm">{linkText}</a>}
                        </div>
                        </>
                    );
                };

                let notificationContent;

                if (isCustomerAdmin) {
                    if (trial_extension === 1) {
                        notificationContent = generateNotificationContent('CustomerAdmin', 'Manage plan', `${pathPrefix + path.SETTINGS}?tab=plan`);
                    } else {
                        notificationContent = generateNotificationContent('Extended', 'Register', productUrl + "plan", '_blank');
                    }
                } else {
                    notificationContent = generateNotificationContent('User');
                }

                updateNotificationBanner(true, notificationContent, shouldShowError ? 'error' : undefined);
            }
        };

        fetchFreeTrial();
    }, [subscriptionPlan]);

    const handleClickOutside = (event) => {
        if (imageSearchBox.current && !imageSearchBox.current.contains(event.target)) {
            setIsImageSearchModalOpen(false);
            cancelUploadProcess();
        }
    };

    useEffect(() => {
        if (isImageSearchModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            if (isTablet) {
                document.body.style.overflow = 'hidden';
            }
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            if (isTablet) {
                document.body.style.overflow = '';
            }
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isImageSearchModalOpen]);

    return (
        <>
            {
                props.searchShow &&
                    <section className="continer-fluid search-view-section d-flex align-items-center">
                        <div className="container">
                            <div className="justify-content-sm-center">
                                <div className="search-group">
                                    <h2 className="search-title display">{Localization.Search.SearchTitle}</h2>
                                    <div className={`input-btn-group text-center d-flex justify-content-center ${inputFocus ? 'blackborder' : ''}`}>
                                        <div className="mobile-search-dropdown" onClick={() => handleMobileModalOpen()}>
                                            <div className="selected-icon">
                                                { selectedItem === 'All Assets' && <img src="./images/icons/all_assets.svg" alt="All Assets" /> }
                                                { selectedItem === 'Images' && <img src="./images/icons/images.svg" alt="Images" /> }
                                                { selectedItem === 'Videos' && <img src="./images/icons/videos.svg" alt="Videos" /> }
                                                { selectedItem === 'Documents' && <img src="./images/icons/documents.svg" alt="Documents" /> }
                                                { selectedItem === 'Audio' && <img src="./images/icons/audio.svg" alt="Audio" /> }
                                            </div>
                                            {isMobileModalOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                                        </div>
                                        <Dropdown className="search-dropdown" onSelect={handleSelect} onToggle={handleToggle}>
                                        <Dropdown.Toggle variant="" id="dropdown-options">
                                            {selectedItem}
                                            {isOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
                                        </Dropdown>

                                        <div className="input-group explore-input-container">
                                            <input disabled={mobileNavigationMenu} type="text" ref={searchInputRef} className="form-control search-input ps-2" placeholder="Search for assets"
                                                aria-label="Search" aria-describedby="button-search" value={inputValue} onChange={handleInput} onKeyDown={handleKeyDown} onFocus={handleInputFocus} onBlur={handleInputBlur} />
                                            {isTablet ? (
                                                inputValue ? (
                                                    <button className="btn-close" type="button" id="button-close" onClick={handleClearClick} title="Clear">
                                                        <CloseIcon color="#ffffff" />
                                                    </button>
                                                ) : (
                                                    subscriptionPlan === "AddOn" &&
                                                    <>
                                                        <button className="btn-search" type="button" id="button-image-search" onClick={handleImageSearchClick} title="Search by image">
                                                            <FilterCenterFocusIcon color="#ffffff" />
                                                        </button>
                                                        <input
                                                            className="upload-inputs d-none"
                                                            {...getInputProps()}
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            capture="environment"
                                                            ref={imageinputRef}
                                                        />
                                                    </>

                                                )
                                            ) : (
                                                inputValue ? (
                                                    <>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip className="custom-tooltip paragraph-2">Clear</Tooltip>}
                                                    >
                                                        <button className="btn-close" type="button" id="button-close" onClick={handleClearClick}>
                                                            <CloseIcon color="#ffffff" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip className="custom-tooltip paragraph-2">Search</Tooltip>}
                                                    >
                                                        <button className="btn-search" type="button" id="button-search" onClick={handleClick}>
                                                            <SearchIcon color="#ffffff" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    </>
                                                ) : (
                                                    subscriptionPlan === "AddOn" &&
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip className={`custom-tooltip paragraph-2 ${isImageSearchModalOpen ? 'd-none' : ''}`}>
                                                              Image analysis
                                                            </Tooltip>
                                                          }
                                                    >
                                                        <button className="btn-search" type="button" id="button-image-search" onClick={handleImageSearchClick}>
                                                            <FilterCenterFocusIcon color="#ffffff" />
                                                        </button>
                                                    </OverlayTrigger>
                                                )
                                            )}
                                            <GenericModal
                                                modalCloseButton={true}
                                                className="image-search-loading-modal"
                                                modalShow={modalImageLoadingShow}
                                                handleModalClose={handleImageLoadingModalClose}
                                                modalType="imageSearchLoading"
                                            />
                                        </div>
                                        {isImageSearchModalOpen && <div className="image-search-container" ref={imageSearchBox}><ImageSearchUpload setIsImageSearchModalOpen={setIsImageSearchModalOpen} /></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            }
            <AssetList
                    title={Localization.Search.AssetList.title}
                    menuTabKey={props.menuTabKey}
                    progress={progress}
                    data={data}
                    handleViewAssetOpen={handleViewAssetOpen}
                    updateAssetData={updateAssetData}/>
            {/* {
                viewAssetsShow &&
                    <ViewAssets
                        updateFSLightBox={props.updateFSLightBox}
                        fetchedFileData={fetchedFileData}
                        nextUUID={nextUUID}
                        prevUUID={prevUUID}
                        viewAssetsShow={viewAssetsShow}
                        viewAssetBtnDisable={viewAssetBtnDisable}
                        handleViewNext={handleViewNext}
                        handleViewPrev={handleViewPrev}
                        handleViewClose={handleViewClose}
                        fileData={fileData}
                        owners={owners}
                        updateSearchValue={updateSearchValue}/>
            } */}
            {
                isMobileModalOpen && (
                    <MobileModal title="File type" onClose={handleMobileModalClose}>
                        <div className="mobile-modal-search-type-icon">
                            <ul>
                                {mobileFileTypeList}
                            </ul>
                        </div>
                    </MobileModal>
                )
            }
            <GenericModal
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                modalType={modalType}
                handleModalDelete={handleModalDelete}
                deleteFilesData={deleteFilesData}
            />
            {
                notificationBannerShow &&
                    <NotificationBanner content={notificationBannerContent} variant={notificationBannerVariant}/>
            }
        </>
    )
}

export default ExploreView;